import {
  bookingsUouBookingsFormClicks,
  bookingsUouBookingsFormClicks1185,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { FormClickAction } from '../../../../types/biLoggerTypes';
import { CreateActionParams } from '../actions';

export type OnBackButtonClick = () => void;

export function createOnBackButtonClickAction({
  context: { biLogger },
}: CreateActionParams): OnBackButtonClick {
  return async () => {
    biLogger?.report(
      bookingsUouBookingsFormClicks({ action: FormClickAction.Back }),
    );
    biLogger?.report(
      bookingsUouBookingsFormClicks1185({ action: FormClickAction.Back }),
    );
  };
}
