import { IWithErrorHandingParams } from '../types';
import { IHttpClient, ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { Experiments } from '@wix/yoshi-flow-editor';
import { ServicesCatalogServer } from '@wix/ambassador-services-catalog-server/http';
import { BookingsServer } from '@wix/ambassador-bookings-server/http';
import { CalendarServer } from '@wix/ambassador-calendar-server/http';
import { AvailabilityCalendar } from '@wix/ambassador-availability-calendar/http';
import { CouponsServer } from '@wix/ambassador-coupons-server/http';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { TotalsCalculator } from '@wix/ambassador-totals-calculator/http';
import { MembershipsSpiHost } from '@wix/ambassador-memberships-spi-host/http';
import { Booking } from '../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { IFlowApiAdapter } from '../../utils/flow-api-adapter/types';
import { CreateBookingErrorType } from '../../types/errors';
import { mapPlatformError } from '../../utils/errors/errors';

export function withErrorBoundary<ResponseType, ErrorType>(
  { fn, mapError, fallback }: IWithErrorHandingParams<ResponseType, ErrorType>,
  reportError: (error: string | Error) => string | undefined,
) {
  return fn()
    .then((data) => ({ data, error: undefined, message: undefined }))
    .catch((e) => {
      const { shouldReport, error } = mapError(e);
      if (shouldReport) {
        reportError(e as Error);
      }
      return {
        ...(error === CreateBookingErrorType.VIOLATIONS_WITH_ERROR_SEVERITY
          ? {
              message: mapPlatformError(e.response)?.applicationError?.data
                ?.violations?.[0]?.description,
            }
          : {}),
        data: fallback,
        error,
      };
    });
}

export type QueryBookingsArgs = {
  bookingIds: string[];
};

export type FormApiContext = {
  httpClient: IHttpClient;
  flowApi: ControllerFlowAPI;
  authorization: string;
  queryBookings: (args: QueryBookingsArgs) => Promise<Booking[]>;
  catalogServer: ReturnType<typeof ServicesCatalogServer>;
  bookingsServer: ReturnType<typeof BookingsServer>;
  calendarServer: ReturnType<typeof CalendarServer>;
  availabilityCalendarServer: ReturnType<typeof AvailabilityCalendar>;
  couponsServer: ReturnType<typeof CouponsServer>;
  membersServer: ReturnType<typeof MembersNgApi>;
  totalsCalculatorServer: ReturnType<typeof TotalsCalculator>;
  membershipsServer: ReturnType<typeof MembershipsSpiHost>;
  experiments: Experiments;
  wixSdkAdapter: IFlowApiAdapter;
  reportError: (error: string | Error) => string | undefined;
};

export type PartialFormApiContext = Partial<FormApiContext>;

export const generateLineItemId = () => {
  // Ecom's GUID format
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const removeTimezoneOffsetFromLocalDateString = (date: string) =>
  date.split('+')[0];
