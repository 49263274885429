import { ServerErrorType } from '../../../types/errors';
import { OnError } from '../../types';
import { FormApiContext, withErrorBoundary } from '../utils';
import { ScheduleAvailability } from '@wix/ambassador-availability-calendar/types';

export type GetScheduleAvailabilityArgs = {
  scheduleId: string;
  onError?: OnError;
};
export const getScheduleAvailability = async ({
  experiments,
  reportError,
  availabilityCalendarServer,
  authorization,
  scheduleId,
  onError,
}: GetScheduleAvailabilityArgs &
  FormApiContext): Promise<ScheduleAvailability> => {
  const { data, error } = await withErrorBoundary(
    {
      fn: async () => {
        const { availability } = await availabilityCalendarServer
          .AvailabilityCalendar()({ Authorization: authorization })
          .getScheduleAvailability({
            scheduleId,
            shouldNotCallConsistentQuery: false,
          });
        return availability;
      },
      mapError: (e) => ({
        error: ServerErrorType.NO_COURSE_AVAILABILITY,
        shouldReport: true,
      }),
      fallback: {
        openSpots: 1,
      },
    },
    reportError,
  );

  if (error) {
    onError?.(error);
  }
  return data!;
};
