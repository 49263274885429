import type { Experiments } from '@wix/yoshi-flow-editor';
import { IncompleteFormSelectedSlot } from '../../../types/types';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { FormApi } from '../../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { EmptyStateErrorType, FormError } from '../../../types/errors';
import { getCurrentTimezone } from '../../timezone/timezone';
import { ExperimentsConsts } from '../../../consts/experiments';
import { isServiceExist } from '../../validations/Validations';
import { OnError } from '../../../api/types';
import { IUser, ReportError } from '@wix/yoshi-flow-editor';
import { IFlowApiAdapter } from '../../flow-api-adapter/types';
import { Form } from '../../../types/ambassador/bookings/ambassador-services-catalog';

export const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
  formSelectedSlot: uncompletedFormNestedSlot,
  timezone,
  experiments,
  isMultiServiceAppointmentEnabled = true,
  reportError,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: IFlowApiAdapter;
  formSelectedSlot: IncompleteFormSelectedSlot;
  timezone: string;
  experiments: Experiments;
  isMultiServiceAppointmentEnabled?: boolean;
  reportError: ReportError;
  isSMSAutomationsEnabled?: boolean;
}) => {
  const formSelectedSlot = uncompletedFormNestedSlot as FormSelectedSlot;
  let errors: FormError[] = [];

  if (!isServiceExist(formSelectedSlot)) {
    errors = [...errors, { errorType: EmptyStateErrorType.INVALID_SERVICE_ID }];
    console.error(errors);
    return {
      errors,
    };
  }

  const isFormUseAutomationsForSMS = experiments.enabled(
    ExperimentsConsts.FormUseAutomationsForSMS,
  );

  const isFormPaymentOptionNewDropdownEnabled = experiments.enabled(
    ExperimentsConsts.FormPaymentOptionNewDropdown,
  );

  const user = controllerConfig.wixCodeApi.user.currentUser;

  const [
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    isUpsellPluginInstalled,
    isSMSAutomationsEnabled,
  ] = await Promise.all([
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    formApi.isMembersAreaInstalled(),
    formApi.isUpsellPluginInstalled(),
    ...(isFormUseAutomationsForSMS
      ? [formApi.isSMSAutomationsEnabled()]
      : [undefined]),
  ]);
  const isLoggedInUser = user.loggedIn;
  const shouldGetPricingPlanDetails = isPricingPlanInstalled && isLoggedInUser;
  const shouldGetPublicPlans =
    isPricingPlanInstalled &&
    (isFormPaymentOptionNewDropdownEnabled || isMultiServiceAppointmentEnabled);

  const [
    areCouponsAvailable,
    catalogData,
    benefitsWithPlanInfo,
    publicPlans,
    memberships,
    serviceOptionsAndVariants,
    memberDetails,
  ] = await Promise.all([
    formApi.areCouponsAvailableForService(),
    formApi.getCatalogData({
      formSelectedSlot,
      onError: () => {
        errors = [
          ...errors,
          { errorType: EmptyStateErrorType.INVALID_CATALOG_DATA },
        ];
      },
    }),
    formApi.getPricingPlansBenefitList(),
    shouldGetPublicPlans ? formApi.getPublicPlans() : undefined,
    shouldGetPricingPlanDetails && !wixSdkAdapter.isOwner()
      ? formApi
          .listMemberships({
            formSelectedSlot,
            onError: (errorType) => (errors = [...errors, { errorType }]),
          })
          .catch((e) => {
            errors = [...errors, e];
            return undefined;
          })
      : undefined,
    formApi
      .getOptionsAndVariantsData({
        formSelectedSlot,
        onError: (errorType) => (errors = [...errors, { errorType }]),
      })
      .catch((e) => {
        errors = [...errors, e];
        return undefined;
      }),
    initMemberDetails({
      formApi,
      onError: (errorType) => (errors = [...errors, { errorType }]),
      reportError,
      user,
    }),
  ]);

  if (!catalogData) {
    return {
      errors,
    };
  }

  const shouldGetLineItemOptions = await wixSdkAdapter.isCartEnabled(
    catalogData.businessInfo,
  );

  const bookingsLineItemOptions = shouldGetLineItemOptions
    ? await formApi.getLineItemOptionsFromCart()
    : undefined;

  if (!isServiceExist(formSelectedSlot)) {
    errors = [
      ...errors,
      { errorType: EmptyStateErrorType.INVALID_SLOT_AVAILABILITY },
    ];
    return {
      errors,
    };
  }
  const numberOfSessions: { [key: string]: number } = {};
  const nestedSlot = formSelectedSlot.nestedSlots[0];
  // TODO: Support multi slots - to support calss/course also with more slots
  const service = catalogData?.serviceList[0].service;
  const activeSchedule = service.schedule;
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart?.toISOString();
  const lastSessionEnd = activeSchedule?.lastSessionEnd?.toISOString();

  const type = service.type;
  numberOfSessions[service.id!] = 1;
  const isCourse = type === ServiceType.COURSE;

  if (isCourse) {
    if (!firstSessionStart) {
      errors = [
        ...errors,
        { errorType: EmptyStateErrorType.COURSE_WITHOUT_SESSIONS },
      ];
      return {
        errors,
      };
    }
    const [listSlots, scheduleAvailability] = await Promise.all([
      lastSessionEnd
        ? formApi.getSlots({
            firstSessionStart: firstSessionStart!,
            lastSessionEnd: lastSessionEnd!,
            scheduleId,
            onError: (errorType) => (errors = [...errors, { errorType }]),
          })
        : {},
      formApi.getScheduleAvailability({ scheduleId }),
    ]);

    if (scheduleAvailability) {
      const openSpots = scheduleAvailability?.openSpots;

      const resolvedTimezone = getCurrentTimezone({
        pageApiTimezone: timezone,
        businessInfo: catalogData.businessInfo,
      });
      formSelectedSlot.openSpots = openSpots;
      formSelectedSlot.timezone = resolvedTimezone;

      if (nestedSlot) {
        nestedSlot.startDate = firstSessionStart;
        nestedSlot.endDate = lastSessionEnd!;
      }
    }

    numberOfSessions[nestedSlot.serviceId] =
      (listSlots as any)?.slots?.length || 1;
  }

  const [formToUse] = await Promise.all([
    formApi.getForm({
      services: catalogData.serviceList,
    }),
  ]);

  return {
    catalogData,
    benefitsWithPlanInfo,
    publicPlans,
    numberOfSessions,
    formSelectedSlot,
    memberships,
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    isUpsellPluginInstalled,
    errors,
    areCouponsAvailable,
    bookingsLineItemOptions,
    serviceOptionsAndVariants,
    memberDetails,
    formToUse: formToUse as Form,
    isSMSAutomationsEnabled,
  };
};

const initMemberDetails = async ({
  formApi,
  user,
  onError,
  reportError,
}: {
  formApi: FormApi;
  user: IUser;
  onError: OnError;
  reportError: ReportError;
}) => {
  const shouldGetMemberDetails = user.loggedIn;
  const memberDetails = shouldGetMemberDetails
    ? await formApi
        .getMemberDetails({
          id: user.id,
          onError,
        })
        .catch((e) => {
          onError(e);
          reportError(e);
          return undefined;
        })
    : undefined;
  return memberDetails;
};
