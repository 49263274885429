import { FormApiContext } from '../utils';

export const fetchLocaleData = async ({
  httpClient,
  experiments,
  flowApi,
}: FormApiContext): Promise<{ [key: string]: string }> => {
  if (!httpClient) {
    return {};
  }

  try {
    const data = await httpClient?.get(
      `https://static.parastorage.com/services/locale-dataset-data/1.4100.0/translations/messages_${flowApi.environment.language}.json`,
    );

    return data!.data as { [key: string]: string };
  } catch (e) {
    console.error(
      `Failed to fetch locale data for ${flowApi.environment.language}, falling back to en`,
      e,
    );

    const data = await httpClient?.get(
      `https://static.parastorage.com/services/locale-dataset-data/1.4100.0/translations/messages_en.json`,
    );

    return data!.data as { [key: string]: string };
  }
};
