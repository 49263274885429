import { FormApi } from '../../../api/FormApi';
import { ControllerFlowAPI, Experiments } from '@wix/yoshi-flow-editor';
import { EmptyStateErrorType } from '../../../types/errors';
import { createDummyState } from '../../dummies/dummy-data';
import { isCartEnabled } from '../utils/utils';
import { ExperimentsConsts } from '../../../consts/experiments';
import { IFlowApiAdapter } from '../../flow-api-adapter/types';

export async function getDummyData(
  formApi: FormApi,
  wixSdkAdapter: IFlowApiAdapter,
  flowApi: ControllerFlowAPI,
  experiments: Experiments,
) {
  const [
    catalogData,
    isPricingPlanInstalled,
    isMemberAreaInstalled,
    isUpsellPluginInstalled,
  ] = await Promise.all([
    formApi.getCatalogData(),
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    formApi.isMembersAreaInstalled(),
    formApi.isUpsellPluginInstalled(),
  ]);

  if (!catalogData) {
    throw EmptyStateErrorType.INVALID_CATALOG_DATA;
  }

  const isMultiServiceAppointmentEnabled = experiments.enabled(
    ExperimentsConsts.MultiServiceAppointment,
  );

  const isMultiServicesAppointmentsEnable =
    catalogData.businessInfo.isMultiServicesAppointmentsEnable &&
    isMultiServiceAppointmentEnabled;

  return createDummyState({
    flowApi,
    businessInfo: catalogData.businessInfo,
    isMemberAreaInstalled,
    isPricingPlanInstalled,
    isMultiServicesAppointmentsEnable,
    isUpsellPluginInstalled,
    isCart: await isCartEnabled({
      experiments: flowApi.experiments,
      wixSdkAdapter,
      businessInfo: catalogData!.businessInfo,
    }),
    experiments,
  });
}
